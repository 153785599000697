<template>
    <!-- <nav class="navbar navbar-expand-sm navbar-light bg-light fixed-top px-0 py-0 justify-content-center"> -->
    <nav class="navbar navbar-expand-sm navbar-light fixed-top px-0 py-0 justify-content-center">
        <div class="container-fluid">
            <div class="navbar-brand">
                <a href="#" data-bs-toggle="dropdown" v-if="validSession">
                    <img alt="SignOn logo" src="../assets/signon_logo10.1a.png" @click="isMenuOpen = !isMenuOpen" class="align-top isLink" style="height: 32px">
                </a>
                <div v-else>
                    <router-link to="/"><img alt="SignOn logo" src="../assets/signon_logo10.1a.png" class="align-top" style="height: 32px"></router-link>
                </div>
                <!-- <div v-if="isLogin"  Enerson: Session expired still can access login -->
                <!-- <div v-if="!(userId === '' || userId === 'null' || userId === null)" -->
                <div v-if="validSession" 
                    class="dropdown-menu rounded-0 navbar-light-content text-primary" style="margin-top: 0px; padding: 5px 0px; width: 100%; opacity: 0.95;">
                    <!-- class="dropdown-menu rounded-0 bg-light text-primary" style="margin-top: 0px; padding: 5px 0px; width: 100%; opacity: 0.95;"> -->
                    <div class="row py-3 px-4">
                        <div class="col-md-4 pb-4" v-for="(menuCat, menuCatIdx) in arrMenu" :key="menuCat">
                            <div class="row">
                                <div class="col-12 navbar-light-title" style="padding-left: 28px;">{{menuCat.category}}</div>
                                <div class="col-12 d-flex flex-wrap" style="max-width: 420px">
                                    <div class="menuBox" v-for="(m, mIdx) in menuCat.menus" :key="m">
                                        <router-link to="/dashboard" v-if="m === 'Dashboard'"><fa icon="gauge" class="menu-icon" /><div class="menu-text">{{m}}</div></router-link>
                                        <router-link to="/project" v-if="m === 'Rack'"><fa icon="folder" class="menu-icon" /><div class="menu-text">{{m}}</div></router-link>
                                        <router-link to="/" v-if="m === 'Report'"><fa icon="clipboard-list" class="menu-icon" /><div class="menu-text">{{m}}</div></router-link>
                                        <!-- <router-link to="/doc/verify" v-if="m === 'Verify Signed Doc'"><fa icon="shield" class="menu-icon" /><div class="menu-text">{{m}}</div></router-link> -->
                                        <router-link to="/apiIntegration" v-if="m === 'API'"><fa icon="diagram-project" class="menu-icon" /><div class="menu-text">{{m}}</div></router-link>
                                        <router-link to="/" v-if="m === 'QR SignOn'"><fa icon="qrcode" class="menu-icon" /><div class="menu-text">{{m}}</div></router-link>
                                        <router-link to="/contact/list" v-if="m === 'Contact'" class="linkRouter"><fa icon="user-circle" class="menu-icon" /><div class="menu-text">{{m}}</div></router-link>
                                        
                                        <router-link to="/team/list" v-if="m === 'Team'"><fa icon="users" class="menu-icon" /><div class="menu-text">{{m}}</div></router-link>
                                        <router-link to="/workflow" v-if="m === 'Workflow'"><fa icon="sitemap" class="menu-icon" /><div class="menu-text">{{m}}</div></router-link>
                                        <router-link to="/" v-if="m === 'Form'"><fa icon="file-invoice" class="menu-icon" /><div class="menu-text">{{m}}</div></router-link>
                                        <router-link to="/backoffice/configuration" v-if="m === 'Configuration'"><fa icon="screwdriver-wrench" class="menu-icon" /><div class="menu-text">{{m}}</div></router-link>
                                        <router-link to="/backoffice/subscriber" v-if="m === 'Subscriber'"><fa icon="cart-plus" class="menu-icon" /><div class="menu-text">{{m}}</div></router-link>
                                        <router-link to="/backoffice/kyc" v-if="m === 'KYC Verification'"><fa icon="address-card" class="menu-icon" /><div class="menu-text">{{m}}</div></router-link>
                                        <router-link to="/backoffice/report" v-if="m === 'Admin Report'"><fa icon="chart-line" class="menu-icon" /><div class="menu-text">{{m}}</div></router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            
            <!-- <div v-if="isLogin"> Enerson: session expired still show login -->
            <!-- <div v-if="!(userId === '' || userId === 'null' || userId === null)"> -->
            <div v-if="validSession">
                <span v-if="getUserStatus !== 'ACTIVE'" class="me-2">
                    <Popper class="popperDark" arrow hover :content="'Inactive user - ' + func.getDateTimeDiffSimple(new Date(), func.convDateTimeFormat(getUserExpired)) + ' left for account activation'">
                        <fa icon="user-clock" class="navbar-display-name isLink" style="font-size: 18px" data-bs-toggle="modal" data-bs-target="#mdlActivateUser" />
                    </Popper>
                </span>

                <!-- <span v-if="getUserIsVerified === 'true' || getUserIsVerified === true" class="me-2">
                    <Popper class="popperDark" arrow hover content="KYC verified user">
                        <fa icon="user-shield" class="navbar-display-name" style="font-size: 18px" />
                    </Popper>
                </span>
                <span v-else class="me-2">
                    <router-link to="/auth/manageaccount">
                        <Popper class="popperDark" arrow hover content="Unverified user - Complete KYC verification in Manage Account to have more access">
                            <fa icon="user-lock" class="navbar-display-name" style="font-size: 18px; cursor: pointer" />
                        </Popper>
                    </router-link>
                </span> -->

            </div>

            <div class="d-md-none">
                <span v-if="getTheme === 'dark-theme'" @click="setTheme('light-theme')">
                    <fa icon="moon" style="font-size: 20px; cursor: pointer" class="navbar-display-name" />
                </span>
                <span v-else @click="setTheme('dark-theme')">
                    <fa icon="sun" style="font-size: 20px; cursor: pointer" class="navbar-display-name" />
                </span>
            </div>

            <button class="navbar-toggler align-middle py-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                <span v-if="validSession" class="my-0">
                    <Avatar :key="getAvatar + getDisplayName" :isUser="true" :avatar="getAvatar" :displayName="getDisplayName" :isVerified="getUserIsVerified" class="fw-bold navbar-display-name"/>
                </span>
                <span v-else class="navbar-toggler-icon"></span>
            </button>


            <!-- <div v-if="!isLogin" class="collapse navbar-collapse" id="collapsibleNavbar"> Enerson: session expired still show login-->
            <!-- <div v-if="userId === '' || userId === 'null' || userId === null" class="collapse navbar-collapse" id="collapsibleNavbar"> -->
            <div v-if="!validSession" class="collapse navbar-collapse" id="collapsibleNavbar">
                <ul v-if="hideLogin !== true" class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <!-- <span class="nav-link d-none d-md-block isLink" @click="$router.push({ path: '/auth/login' })" >
                            <Popper class="popperDark" arrow hover content="Login">
                                <fa icon="circle-user" size="lg"/>...
                            </Popper>
                        </span>
                        <span class="nav-link d-md-none isLink" @click="$router.push({ path: '/auth/login' })" >
                            Login
                        </span> -->
                        <a href="https://app.signon.my/auth/login">
                            <span class="nav-link d-none d-md-block isLink">
                                <Popper class="popperDark" arrow hover content="Login">
                                    <fa icon="circle-user" class="navbar-display-name" style="font-size: 20px" />
                                </Popper>
                            </span>
                            <span class="nav-link d-md-none isLink" style="color: #6E777E;">
                                Login
                            </span>
                        </a>
                    </li>
                </ul>
            </div>

            <span v-if="!validSession" class="d-none d-md-block float-end">
                <span v-if="getTheme === 'dark-theme'" @click="setTheme('light-theme')">
                    <Popper class="popperDark" arrow hover content="Dark mode">
                        <fa icon="moon" style="font-size: 20px; cursor: pointer" class="navbar-display-name" />
                    </Popper>
                </span>
                <span v-else @click="setTheme('dark-theme')">
                    <Popper class="popperDark" arrow hover content="Light mode">
                        <fa icon="sun" style="font-size: 20px; cursor: pointer" class="navbar-display-name" /></Popper>
                </span>
            </span>

            <!-- <div v-if="isLogin" class="collapse navbar-collapse" id="collapsibleNavbar"> Enerson: session expired still show login-->
            <!-- <span>validSession: {{validSession}} - {{typeof validSession}}</span> -->
            
            <div v-if="validSession" class="collapse navbar-collapse" id="collapsibleNavbar">
                <!-- Large screen -->

                <ul class="navbar-nav ms-auto d-none d-md-block">
                    <li class="nav-item dropdown align-middle">
                        <a class="nav-link py-0" href="#" role="button" data-bs-toggle="dropdown">
                            <table>
                                <tr>
                                    <td>
                                        <Avatar :key="getAvatar + getDisplayName" :isUser="true" :avatar="getAvatar" :displayName="getDisplayName" :isVerified="getUserIsVerified" class="fw-bold navbar-display-name ps-2"/>
                                        <!-- <Avatar :key="getAvatar + getDisplayName" :isUser="true" :avatar="getAvatar" :displayName="getDisplayName" isVerified="true" class="fw-bold navbar-display-name ps-2"/> -->
                                    </td>
                                    <td class="ps-1">
                                        <span v-if="getTheme === 'dark-theme'" @click="setTheme('light-theme')">..
                                            <Popper class="popperDark" arrow hover content="Dark mode"><fa icon="moon" style="font-size: 20px; cursor: pointer" class="navbar-display-name" /></Popper>
                                        </span>
                                        <span v-else @click="setTheme('dark-theme')">
                                            <Popper class="popperDark" arrow hover content="Light mode"><fa icon="sun" style="font-size: 20px; cursor: pointer" class="navbar-display-name" /></Popper>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-profile" style="font-size: 14px">
                            <li @click="$router.push({path: '/auth/preference'})"><span class="dropdown-item greyLink">Preferences</span></li>
                            <li @click="logout"><span class="dropdown-item greyLink">Logout</span></li>
                        </ul>
                        
                    </li>
                </ul>

                <!-- Small screen -->
                <ul class="navbar-nav ms-auto d-md-none">
                    <li class="nav-item dropdown align-middle">
                        <router-link to="/auth/preference"><span class="dropdown-item greyLink px-0">Preferences</span></router-link>
                        <a class="nav-link" href="#" @click="logout"><span class="greyLink">Logout</span></a>
                    </li>
                </ul>

            </div>
           
        </div>
    </nav>


    <!-- <nav class="navbar navbar-expand-sm justify-content-center fixed-top py-0 border-top"  -->
    <nav v-if="!hideSecondNavbar" class="navbar navbar-expand-sm justify-content-center fixed-top py-0 py-3" 
        style="margin-top: 41px; z-index: 1"> <!-- 0 -->
        <div class="navbar-nav">

            <div v-if="title === 'Document Preview'" class="fw-bold" style="font-size: 20px; padding-top: 1px;">
                <span><fa :icon="['fab', 'searchengin']" class="me-2"/></span>
                {{ title }}
            </div>
            <div v-else-if="title && icon !== 'bars'" class="fw-bold" style="font-size: 20px; padding-top: 1px;">
                <span v-if="icon"><fa :icon="icon" class="me-2"/></span>
                {{ title }}
            </div>
            
            <!-- large screen -->
            <div v-if="!title" class="d-none d-md-block py-1">
                <span :class="step === '1' ? 'selectedMenu' : ''">
                    <!-- <span v-if="step === '1'" class="text-primary"><fa icon="upload" size="lg" class="me-1"/> Upload</span>
                    <span v-else><fa icon="upload" size="lg" class="me-1"/> Upload</span> -->
                    <span v-if="step === '1'"><fa icon="upload" size="lg" class="me-1"/> Upload</span>
                    <router-link v-else :to="lnkUploadDoc"><fa icon="upload" size="lg" class="me-1"/> Upload</router-link>
                </span>
                <span class="px-4"><fa icon="caret-right" size="lg" class="me-1"/></span>
                <span :class="step === '2' ? 'selectedMenu' : ''">
                    <span v-if="disabledSignee"><fa icon="user-circle" size="lg" class="me-1"/> Signee</span>
                    <router-link v-else to="/addSignee"><fa icon="user-circle" size="lg" class="me-1"/> Signee</router-link>
                </span>
                <span class="px-4"><fa icon="caret-right" size="lg" class="me-1"/></span>
                <span :class="step === '3' ? 'selectedMenu' : ''">
                    <span v-if="disabledSignatory"><fa icon="file-signature" size="lg" class="me-1"/> Signatory</span>
                    <router-link v-else to="/signatory"><fa icon="file-signature" size="lg" class="me-1"/> Signatory</router-link>
                </span>
                <span class="px-4"><fa icon="caret-right" size="lg" class="me-1"/></span>
                <span :class="step === '4' ? 'selectedMenu' : ''">
                    <span v-if="disabledReview"><fa icon="envelope-open" size="lg" class="me-1"/> Review</span>
                    <router-link v-else to="/reviewInvite"><fa icon="envelope-open" size="lg" class="me-1"/> Review</router-link>
                </span>
            </div>

            <!-- small screen -->
            <div v-if="!title" class="d-block d-md-none py-1">
                <span :class="step === '1' ? 'selectedMenu' : ''">
                    <span v-if="step === '1'" class="text-primary">Upload</span>
                    <span v-else>Upload</span>
                    <!-- <span v-if="disabledAll" class="text-primary">Upload</span>
                    <router-link v-else :to="lnkUploadDoc">Upload</router-link> -->
                </span>
                <span class="px-3"><fa icon="caret-right" size="lg" class="me-1"/></span>
                <span :class="step === '2' ? 'selectedMenu' : ''">
                    <span v-if="disabledSignee">Signee</span>
                    <router-link v-else to="/addSignee">Signee</router-link>
                </span>
                <span class="px-3"><fa icon="caret-right" size="lg" class="me-1"/></span>
                <span :class="step === '3' ? 'selectedMenu' : ''">
                    <span v-if="disabledSignatory">Signatory</span>
                    <router-link v-else to="/pdf/detail">Signatory</router-link>
                </span>
                <span class="px-3"><fa icon="caret-right" size="lg" class="me-1"/></span>
                <span :class="step === '4' ? 'selectedMenu' : ''">
                    <span v-if="disabledReview">Review</span>
                    <router-link v-else to="/reviewInvite">Review</router-link>
                </span>
            </div>
        </div><!-- navbar-nav end-->
    </nav>

    <!-- Modal --> 
    <div class="modal fade" id="mdlActivateUser" tabindex="-1" aria-labelledby="AddNote" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Account activation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">
                            Your account is registered but not activated, please activate your account.
                        </div>
                        <div class="col-12">
                            If you do not receive activation email, please click 
                            <span class="isLink" @click="resendActivation"><u>here</u></span> 
                            to send again, wait 5 minutes and check your junk/spam folder.
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                </div>
            </div>
        </div>
    </div><!-- Modal End-->

</template>

<script>
import { ref, computed, inject, onMounted } from 'vue'
import { useStore } from '@/stores/store'
import { useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
// import cookie from '@/functions/cookie'
import auth from '@/functions/auth'
import Popper from 'vue3-popper'
import Avatar from '@/components/Avatar.vue'
import funcs from '@/functions/function'

export default {
    props: [ 'title', 'icon', 'step', 'disabledAll', 'disabledSignee', 'disabledSignatory', 'disabledReview', 'hideLogin', 'hideSecondNavbar' ],
    components: { Alert, Popper, Avatar },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const func = funcs

        const sessionId = ref(null)
        const lnkUploadDoc = ref('/uploadDoc')
        const lnkSignatory = ref('/pdf/detail')
        const { getDisplayName, getAvatar, getTheme, getUserIsVerified, getUserStatus, getUserId, getUserExpired, updUserIsVerified } = useStore() // reflect immediately
        const store = useStore()
        const router = useRouter()

        const arrMenu = ref([])
        const theme = ref('light-theme')
        const userExpired = ref(null)
        const userStatus = ref(null)
        const userIsVerified = ref(false)
        const userId = ref('')
        const validSession = ref(false)

        // console.info('Top Navigation', store.getUserIsVerified.value, localStorage.getItem('userIsVerified'))

        sessionId.value = store.getSessionId.value
        // console.info('sessionId ' + typeof sessionId.value, sessionId.value)
        // console.info('getAvatar', getAvatar.value)
        // console.info('getDisplayName', getDisplayName.value)

        lnkUploadDoc.value = '/uploadDoc/' + (store.getProjectId.value === 'null' ? '' : store.getProjectId.value)
        lnkSignatory.value = '/pdf/detail/' + (store.getProjectId.value === 'null' ? '' : store.getProjectId.value)

        const logout = async () => {
            const isLogout = await auth.logout()

            if (isLogout[0] === '1') {
                //ORIG: router.push('/auth/login')
                router.push({ name: 'Login', query: { m: '1' } })
                .then(() => {
                    router.go() // refresh the page so that can capture sessionId in store
                })
            } else {

                if (isLogout[1] === 'no_session') {
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: 'Logout failed. Session not found.'
                    })

                } else if (isLogout[1] === 'user_not_online') {
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: 'Logout failed. User not online.'
                    })

                } else {

                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: 'Logout failed. ' + isLogout[1]
                    })
                }
            }
        }

        const setTheme = async (theme1) => {
            theme.value = computed({
                get: () => store.getTheme.value,
                set: store.updTheme(theme1),
            })
            theme.value = store.getTheme.value

            document.documentElement.className = theme1
        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const resendActivation = () => {
            // Resend activation link fr logged in user no need captcha
            const p = {
                username: store.getUserId.value,
                frontendUrl: 'https://signon.my/dashboard'
            }

            axios.post('/auth/signup/resend', p)
                .then((res) => {
                    if (res.data.status === 1) {
                        alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "An activation email has sent to you, please click the link to activate it."
                            })
                    }
                })
                .catch((error) => {
                    console.log('resendActivation | ERR', JSON.stringify(error))
                })
        }

        /* const isLogin = computed(() => {
            if (store.getUserId.value === null || store.getUserId.value === '') {
                return false
            } else {
                return true
            }
        }) */

        const chkSession = async () => {
            try {
                const response = await axios.get('/auth/session');

                // console.info('TOPNav chkSession', JSON.stringify(response.data.data))
                if( response.data.status === 1) {
                    validSession.value = true

                    userStatus.value = computed({
                        get: () => store.getUserStatus.value,
                        set: store.updUserStatus(response.data.data.status),
                    })
                    userStatus.value = store.getUserStatus.value 

                    userIsVerified.value = computed({
                        get: () => store.getUserIsVerified.value,
                        set: store.updUserIsVerified(response.data.data.isVerified),
                    })
                    userIsVerified.value = store.getUserIsVerified.value 

                } else {
                    validSession.value = false
                    auth.clearStorage()
                }

            } catch (error) {
                validSession.value = false
                auth.clearStorage()
            }
        }

        onMounted(async () => {

            if (store.getTheme.value === null && localStorage.getItem('theme') !== undefined && localStorage.getItem('theme') !== null) {
                setTheme(localStorage.getItem('theme'))
            }
            
            await chkSession()

            // console.info('* * * onMounted topNavi ' + typeof store.getUserId.value, store.getUserId.value, userId.value)
            // console.info('cookieExpires', userExpired.value, getUserIsVerified.value, typeof getUserIsVerified.value)
            if (getUserIsVerified.value === 'false') {
                userExpired.value = localStorage.getItem('userExpired')
            }

            arrMenu.value = [
                {
                    category: 'MAIN',
                    menus: ['Dashboard', 'Rack', 'Contact', 'Team']
                },
                {
                    category: 'TOOL',
                    menus: ['API', 'QR SignOn', 'Form', 'Workflow']
                },
                {
                    category: 'BACK OFFICE',
                    menus: ['Configuration', 'Subscriber', 'KYC Verification', 'Admin Report']
                },
            ]
        })

        return { 
            alert, closeAlert, func, router, sessionId, getDisplayName, getAvatar, getUserId, lnkUploadDoc, lnkSignatory, logout,
            theme, setTheme, getTheme, getUserIsVerified, getUserExpired, getUserStatus, store, resendActivation, /* isLogin,  */userExpired,
            userId, validSession, arrMenu
            }
    },
    data () {
        return {
        }
    },
    mounted () {
        // console.info('step', typeof step)
    },
    methods: {
    }
}
</script>

<style>
a:link {
  color: #6C757D;
}

a:visited {
  color: #6C757D;
}

a:hover {
  color: #53BDEB;
}

a:active {
  color: #53BDEB;
}

.selectedMenu {
    color: #53BDEB;
}

.selectedMenu a:link, .selectedMenu a:visited, .selectedMenu a:hover, .selectedMenu a:active {
    color: #53BDEB;
}

.menuBox {
    width: 100px;
    height: 80px;
    text-align: center;
    padding-top: 8px;
    margin-right: 2px;
}

.menu-icon {
    font-size: 32px;
}

.menu-text {
    font-size: 12px;
}

.navbar-nav > .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; 
    right: 0;
 }


</style>