<template>
  <teleport to="#notification">

    <div v-if="alertMessage" ref="alertBox" class="alert-dismissible fade show" :class="alertClass">
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
        <table>
            <tr>
                <td class="align-top pe-2">
                    <span v-if="alertTitle === 'SUCCESS'"><fa icon="circle-check" style="font-size: 36px" /></span>
                    <span v-if="alertTitle === 'INFO'"><fa icon="circle-info" style="font-size: 36px" /></span>
                    <span v-if="alertTitle === 'ERROR'"><fa icon="circle-exclamation" style="font-size: 36px" /></span>
                    <span v-if="alertTitle === 'WARNING'"><fa icon="triangle-exclamation" style="font-size: 36px" /></span>
                </td>
                <td>{{ alertMessage }}</td>
            </tr>
        </table>
    </div>

  </teleport>
</template>

<script>
import {ref, onMounted} from 'vue'
import { useRoute } from 'vue-router'

export default {
    name: "Alert",
    props: [ 'data' ],
    emits: ['closeAlert'],
    setup (props, { emit }) {

        const route = useRoute()

        const alertClass = ref(props.data.class) // alert alert-success
        const alertTitle = ref(props.data.title) // Success!
        const alertMessage = ref(props.data.message)
        const alertBox = ref(null)

        if (alertClass.value !== '') {
            alertClass.value = 'alert alert-' + alertClass.value
        }

        const close = () => {
            emit('closeAlert')
        }

        onMounted(() => {

          if (alertClass.value === 'alert alert-success') {
              setTimeout(() => {
                  /* Array.from(document.querySelectorAll('.alert'))
                  .forEach(alertNode => console.info('node', alertNode)) */
                  emit('closeAlert')
              }, 3000)
          }

          if (alertClass.value === 'alert alert-danger') {
              setTimeout(() => {
                  emit('closeAlert')
              }, 5000)
          }

          if (alertClass.value === 'alert alert-warning') {
              setTimeout(() => {
                  emit('closeAlert')
              }, 5000)
          }

          if (alertClass.value === 'alert alert-info') {
              setTimeout(() => {
                  emit('closeAlert')
              }, 5000)
          }

        })
        

        return { alertClass, alertTitle, alertMessage, close, alertBox }
    }
}
</script>

<style>
</style>