import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import cookie from '@/functions/cookie'

/* Can't get store 
import { useStore } from '@/stores/store'
const { getUserId } = useStore()
console.info('store', getUserId) */

import auth from '@/functions/auth'
import { computed } from 'vue'

// Check if session valid - temp use a test API
let validSession = false

const axios = require('axios').default

async function chkSession() {

    try {
        const response = await axios.get('/auth/session');
        // console.info('ROUTER chkSession', JSON.stringify(response.data.data))

        if( response.data.status === 1) {
            validSession = true

            const userId = localStorage.getItem('userId')

            if (userId === undefined || userId === 'undefined' || userId === null || userId === 'null') {
                console.info('*** *** * ROUTER Missing localStorage - upd localStorage')
                
                localStorage.setItem('userId', response.data.data.userId)
                localStorage.setItem('sessionId', response.data.data.sessionId)
                localStorage.setItem('displayName', response.data.data.displayName)
                if (localStorage.getItem('avatar') === undefined || localStorage.getItem('avatar') === 'undefined' || 
                    localStorage.getItem('avatar') === null || localStorage.getItem('avatar') === 'null') {
                        localStorage.setItem('avatar', response.data.data.avatar)
                }
                localStorage.setItem('role', response.data.data.role)
            }

            localStorage.setItem('userIsVerified', response.data.data.isVerified) // User verify KYC
            localStorage.setItem('userStatus', response.data.data.status) // user activated his account via email link
            localStorage.setItem('userExpired', response.data.data.dateDelete)

        } else {
            validSession = false
            auth.clearStorage()
        }

    } catch (error) {
        console.info('ROUTER chkSession err', error)
        auth.clearStorage()
    }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/legality',
    name: 'LegalityGuide',
    component: () => import('../views/Legality.vue')
  },
  {
    path: '/terms',
    name: 'TermsOfService',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/p/:projectId',
    name: 'ViewProj',
    component: () => import('../views/sign/Proj.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/p/:projectId/:documentId',
    name: 'ViewProjDoc',
    component: () => import('../views/sign/ProjDoc.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/d/:documentId',
    name: 'ViewDocInfo',
    component: () => import('../views/sign/DocInfo.vue'),
    meta: { requiresAuth: true }
  },
/* 
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  },
{
    path: '/module1',
    name: 'Module1',
    component: () => import('../views/Module1.vue')
  },
*/
  {
    path: '/auth/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/auth/signup',
    name: 'SignUp',
    component: () => import('../views/auth/SignUp.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/auth/activate/:activationCode',
    name: 'Activate',
    component: () => import('../views/auth/Activate.vue'),
    // meta: { requiresAuth: false }
  },
  {
    path: '/auth/forgotpassword',
    name: 'ForgotPassword',
    component: () => import('../views/auth/ForgotPassword.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/auth/resetPassword/:activationCode',
    name: 'ResetPassword',
    component: () => import('../views/auth/ResetPassword.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/auth/preference',
    name: 'Preference',
    component: () => import('../views/auth/Preference.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/preference/bill',
    name: 'PreferenceBill',
    component: () => import('../views/auth/Preference.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/preference/upgrade/:packageId',
    name: 'PreferenceUpgrade',
    component: () => import('../views/auth/Preference.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/preference/upgrade/:packageId/payment',
    name: 'PreferencePayment',
    component: () => import('../views/auth/Preference.vue'),
    meta: { requiresAuth: true }
  },
/*   {
    path: '/dashboard/folderdoc',
    name: 'FolderDoc',
    component: () => import('../views/dashboard/FolderDoc.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/invited',
    name: 'Invited',
    component: () => import('../views/dashboard/Invited.vue'),
    meta: { requiresAuth: true }
  }, */
  {
    path: '/dashboard/notification',
    name: 'Invited',
    component: () => import('../views/dashboard/Notification.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/sso',
    name: 'SSO',
    component: () => import('../views/dashboard/Sso.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/outstanding',
    name: 'Outstanding',
    component: () => import('../views/dashboard/Outstanding.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/signed',
    name: 'Signed',
    component: () => import('../views/dashboard/Signed.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/report',
    name: 'DashboardReport',
    component: () => import('../views/dashboard/Report.vue'),
    meta: { requiresAuth: true }
  },
/*   
  {
    path: '/dashboard/report2',
    name: 'DashboardReportTeam',
    component: () => import('../views/dashboard/ReportTeam.vue'),
    meta: { requiresAuth: true }
  },
{
    path: '/dashboard2',
    name: 'Dashboard2',
    component: () => import('../views/dashboard/Overview2.vue'),
    meta: { requiresAuth: true }
  }, */
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/dashboard/Overview.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/project/:projectId',
    name: 'ProjectOverview',
    meta: { requiresAuth: true },
    component: () => import('../views/project/Overview.vue')
  },
  {
    path: '/project',
    name: 'ProjectList',
    component: () => import('../views/project/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/project2',
    name: 'ProjectList2',
    component: () => import('../views/project/List_bk.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/doc/verify',
    name: 'VerifyDoc',
    component: () => import('../views/document/Verify.vue'),
    // meta: { requiresAuth: true }
  },
  {
    path: '/pdf/view/:projectId/:documentId',
    name: 'PdfView',
    component: () => import('../views/pdf/View.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/signatory',
    name: 'Signatory',
    component: () => import('../views/pdf/Detail.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/pdf/detail',
    name: 'PdfDetail',
    component: () => import('../views/pdf/Detail.vue'),
    meta: { requiresAuth: true }
  },
/*   {
    path: '/pdf/detailSignee',
    name: 'PdfDetailSignee',
    component: () => import('../views/pdf/DetailSignee.vue')
  },
  {
    path: '/pdf/detailSignee2',
    name: 'PdfDetailSignee2',
    component: () => import('../views/pdf/DetailSignee2.vue'),
    meta: { requiresAuth: true }
  }, */
  {
    path: '/uploadDoc/:projectId',
    name: 'UploadDoc',
    component: () => import('../views/UploadDoc.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/uploadDoc',
    name: 'UploadDocNew',
    component: () => import('../views/UploadDoc.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/addSignee',
    name: 'AddSignee',
    component: () => import('../views/AddSignee.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reviewInvite',
    name: 'ReviewInvite',
    component: () => import('../views/ReviewInvite.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/apiIntegration',
    name: 'ApiIntegration',
    component: () => import('../views/ApiIntegration.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workflow',
    name: 'WorkFlow',
    component: () => import('../views/workflow/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workflow/add',
    name: 'AddWorkFlow',
    component: () => import('../views/workflow/Create.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workflow/version/list',
    name: 'WorkFlowVersionList',
    component: () => import('../views/workflow/VersionList.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workflow/version/:documentId',
    name: 'WorkFlowVersion',
    component: () => import('../views/workflow/Version.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contact/list',
    name: 'ContactList',
    component: () => import('../views/contact/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contact/:userId',
    name: 'ContactEdit',
    component: () => import('../views/contact/Edit.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/team/list',
    name: 'TeamList',
    component: () => import('../views/team/TeamList.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/team/member/list',
    name: 'MemberList',
    component: () => import('../views/team/MemberList.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/team/member/add/:frPage',
    name: 'AddMember',
    component: () => import('../views/team/AddMember.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/team/member/:userId',
    name: 'Member',
    component: () => import('../views/team/Member.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/team/:teamId/permission',
    name: 'TeamPermission',
    component: () => import('../views/team/Permission.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/backoffice/configuration',
    name: 'BOConfiguration',
    component: () => import('../views/backoffice/configuration/Overview.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/backoffice/kyc',
    name: 'BOKycList',
    component: () => import('../views/backoffice/kyc/List.vue'),
    meta: { requiresAuth: true }
  },
/*   {
    path: '/backoffice/kyc/p/:status/:dateFr:/:dateTo',
    name: 'BOKycListParams',
    component: () => import('../views/backoffice/kyc/List.vue'),
    meta: { requiresAuth: true }
  }, */
  {
    path: '/backoffice/kyc/report',
    name: 'BOKycReport',
    component: () => import('../views/backoffice/kyc/Report.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/backoffice/kyc/:userId',
    name: 'BOKycProfile',
    component: () => import('../views/backoffice/kyc/Profile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/backoffice/subscriber',
    name: 'BOSubscriber',
    component: () => import('../views/backoffice/subscriber/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/backoffice/subscriber/report',
    name: 'BOSubscriberReport',
    component: () => import('../views/backoffice/subscriber/Report.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/backoffice/subscriber/:userId',
    name: 'BOSubscriberProfile',
    component: () => import('../views/backoffice/subscriber/Profile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/backoffice/report',
    name: 'BOReport',
    component: () => import('../views/backoffice/report/Overview.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/backoffice/report2',
    name: 'BOReport2',
    component: () => import('../views/backoffice/report/Overview2.vue'),
    meta: { requiresAuth: true }
  },
/*   {
    path: '/pageNotFound',
    name: 'PageNotFound',
    component: () => import('../views/Error404.vue')
  }, */
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import('../views/Error404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/* const func = funcs
const isSession = func.chkSession()
const s2 = func.chkSession2()
console.info('isSession = ' + typeof isSession, isSession)
console.info('s2 = ' , s2) */


router.beforeEach(async (to, from, next) => {

  await chkSession() // ensure if user login -  session not expired

  const userId = localStorage.getItem('userId')

  let isLogin = true
  if (userId === undefined || userId === null || userId === 'null') {
    isLogin = false
  }

  console.info('*** *** *** ROUTER b4', 'requiresAuth:', to.meta.requiresAuth, 'isLogin', isLogin, 'validSession', validSession)

  // Cannot use store here
  // console.info('ROUTER from Path', from.fullPath) 
  // console.info('ROUTER from Name', from.name) 
  // console.info('ROUTER to', to.path) 
  // console.info('ROUTER to', to) 
  // console.info('ROUTER to.name', to.name) 
  // console.info('ROUTER to.meta.requiresAuth', to.meta.requiresAuth) 
  // console.info('ROUTER to.fullPath', to.fullPath)

  if (from.path === '/signatory') {
    localStorage.removeItem('hasClickSignatoryToolbox') // Handle evertime access signatory show leftDrawer by default
  }

  if (from.path.substring(0, 10) === '/pdf/view/') {
    localStorage.removeItem('hasClickViewToolbox')  // Handle evertime access preview doc show leftDrawer by default
  }
  
  if (to.meta.requiresAuth === true) {
      // console.info('---- req LOGIN')

      if (isLogin && validSession) {
          next()

      } else if ( to.name === 'Login' && to.query.m === '1') {
        // Manual logout
        next({ path: '/auth/login' })

      } else if ((to.name === 'ViewProj' || to.name === 'ViewDocInfo' || to.name === 'ViewProjDoc') && !isLogin && !validSession) {
          if (to.name === 'ViewProj') {
              next({ path: '/auth/login/', query: { redirect: to.name, p: to.params.projectId } })

          } else if ( to.name === 'ViewProjDoc') {
              next({ path: '/auth/login/', query: { redirect: to.name, p: to.params.projectId, d: to.params.documentId } })

          } else if ( to.name === 'ViewDocInfo') {
              next({ path: '/auth/login/', query: { redirect: to.name, d: to.params.documentId } })

          }

      } else {
          if (to.meta.requiresAuth === true && !isLogin && !validSession) {
            next({ path: '/auth/login/'})
          }

          if (to.name === 'ViewProj') {
              next({ path: '/auth/login/', query: { redirect: to.name, p: to.params.projectId, e: 'timeout' } })

          } else if ( to.name === 'ViewProjDoc') {
              next({ path: '/auth/login/', query: { redirect: to.name, p: to.params.projectId, d: to.params.documentId, e: 'timeout' } })

          } else if ( to.name === 'ViewDocInfo') {
              next({ path: '/auth/login/', query: { redirect: to.name, d: to.params.documentId, e: 'timeout' } })

          } else if ( to.name === 'UploadDocNew') {
              next({ path: '/auth/login', query: { redirect: 'UploadDocNew', e: 'timeout' } })

          } else if ( to.name === 'UploadDoc') {
            next({ path: '/auth/login', query: { redirect: 'UploadDoc', p: to.params.projectId } })

          } else if ( to.name === 'ProjectOverview') {
              next({ path: '/auth/login', query: { redirect: 'ProjectOverview', p: to.params.projectId } })

          } else if ( to.name === 'ProjectList') {
              next({ path: '/auth/login', query: { redirect: 'ProjectList', e: 'timeout' } })

          } else if ( to.name === 'VerifyDoc') {
              next({ path: '/auth/login', query: { redirect: 'VerifyDoc', e: 'timeout' } })

          } else if ( to.name === 'PdfView') {
            next({ path: '/auth/login', query: { redirect: 'PdfView', p: to.params.projectId, d: to.params.documentId, e: 'timeout' } })

          } else if ( to.name === 'Signatory') {
            next({ path: '/auth/login', query: { redirect: 'Signatory', e: 'timeout' } })

          } else if ( to.name === 'PdfDetail') {
            next({ path: '/auth/login', query: { redirect: 'PdfDetail', e: 'timeout' } })

          } else if ( to.name === 'AddSignee') {
            next({ path: '/auth/login', query: { redirect: 'AddSignee', e: 'timeout' } })

          } else if ( to.name === 'ReviewInvite') {
            next({ path: '/auth/login', query: { redirect: 'ReviewInvite', e: 'timeout' } })

          } else if ( to.name === 'ManageAccount') {
            next({ path: '/auth/login', query: { redirect: 'ManageAccount', e: 'timeout' } })

          }

      }
      
  } else if ( to.meta.requiresAuth === false) {
      console.info('---- X req LOGIN', to.path)
      // Permission denied - E.g. after login cannot accesss login page
      if (isLogin && validSession) {
          next({ path: '/pageNotFound' })
      } else {
          next()
      }

  }
  else
  {
      // console.info('*** everyone can access')
      next()
  }

})

export default router
