import axios from '@/functions/axios-instance'

export default {
    name: "auth",
    async logout() {
        try {
            const res = await axios.get( '/auth/signout')
            if (res.data.status === 1) {
                // console.info('auth logout -- remove storage')

                localStorage.removeItem('sessionId')
                localStorage.removeItem('userId')
                localStorage.removeItem('displayName')
                localStorage.removeItem('avatar')
                localStorage.removeItem('role')
                localStorage.removeItem('userIsVerified')
                localStorage.removeItem('userStatus')
                localStorage.removeItem('userExpired')
                localStorage.removeItem('userLastLogin')
                localStorage.removeItem('projectId')
                localStorage.removeItem('projectName')

                // ProjDoc
                localStorage.removeItem('leftDrawer')
                localStorage.removeItem('leftFloatDrawer')

                // View
                localStorage.removeItem('hasClickViewToolbox')
                localStorage.removeItem('leftViewDrawer')
                localStorage.removeItem('leftViewFloatDrawer')

                // Signatory
                localStorage.removeItem('hasClickSignatoryToolbox')
                localStorage.removeItem('leftDrawer')
                localStorage.removeItem('signeeClass')
                localStorage.removeItem('signeeId')
                localStorage.removeItem('signeeName')
                localStorage.removeItem('signeeEmail')
                localStorage.removeItem('signee')
                localStorage.removeItem('documentId')
                localStorage.removeItem('documentName')
                localStorage.removeItem('documentUrl')
                localStorage.removeItem('doc')

                // View, ProjDoc, Signatory
                localStorage.removeItem('page')
                localStorage.removeItem('totalPage')


                return [ '1', 'success']

            } else {
                return ['0', res.data.message]
            }

        } catch (err) {
            // Handle Error Here
            console.info('ERR', err)
            return ['0', err]
        }
    },
    clearStorage() {
        // console.info('----- auth.clearStorage')
        localStorage.removeItem('sessionId')
        localStorage.removeItem('userId')
        localStorage.removeItem('displayName')
        localStorage.removeItem('avatar')
        localStorage.removeItem('role')
        localStorage.removeItem('userIsVerified')
        localStorage.removeItem('userStatus')
        localStorage.removeItem('userExpired')
        localStorage.removeItem('userLastLogin')
        localStorage.removeItem('projectId')
        localStorage.removeItem('projectName')

        // ProjDoc
        localStorage.removeItem('leftDrawer')
        localStorage.removeItem('leftFloatDrawer')

        // View
        localStorage.removeItem('hasClickViewToolbox')
        localStorage.removeItem('leftViewDrawer')
        localStorage.removeItem('leftViewFloatDrawer')

        // Signatory
        localStorage.removeItem('hasClickSignatoryToolbox')
        localStorage.removeItem('leftDrawer')
        localStorage.removeItem('signeeClass')
        localStorage.removeItem('signeeId')
        localStorage.removeItem('signeeName')
        localStorage.removeItem('signeeEmail')
        localStorage.removeItem('signee')
        localStorage.removeItem('documentId')
        localStorage.removeItem('documentName')
        localStorage.removeItem('documentUrl')
        localStorage.removeItem('doc')

        // View, ProjDoc, Signatory
        localStorage.removeItem('page')
        localStorage.removeItem('totalPage')
    }
}
