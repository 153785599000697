import axios2 from 'axios'

export default {
    name: "function",
    convDateTimeFormat (d="", format="") {
        // input format: 2021-11-18T23:11:41.000Z (from backend) OR Fri Nov 19 2021 15:11:41 GMT+0800 (Malaysia Time)
        const date = new Date(d)

        if (format === "date") {
            const month = (date.getMonth() * 1 + 1) + ''
            const day = date.getDate() + ''

            return date.getFullYear() + '-' + (month.length === 2 ? month : '0' + month) + '-' + (day.length === 2 ? day : '0' + day)

        } else if (format === "datetime") {
            // convert to YYYY-MM-DD HH:II
            const month = (date.getMonth() * 1 + 1) + ''
            const day = date.getDate() + ''
            const hour = date.getHours() + ''
            const minute = date.getMinutes() + ''

            return date.getFullYear() + '-' + (month.length === 2 ? month : '0' + month) + '-' + (day.length === 2 ? day : '0' + day) + ' ' + (hour.length === 2 ? hour : '0' + hour) + ':' + (minute.length === 2 ? minute : '0' + minute)
        } else if (format === "fulldatetime") {
            // convert to YYYY-MM-DD HH:II:SS
            const month = (date.getMonth() * 1 + 1) + ''
            const day = date.getDate() + ''
            const hour = date.getHours() + ''
            const minute = date.getMinutes() + ''
            const second = date.getSeconds() + ''

            return date.getFullYear() + '-' + (month.length === 2 ? month : '0' + month) + '-' + (day.length === 2 ? day : '0' + day) + ' ' + (hour.length === 2 ? hour : '0' + hour) + ':' + (minute.length === 2 ? minute : '0' + minute) + ':' + (second.length === 2 ? second : '0' + second)
        
        } else {
            // Coverted to FORMAT: Fri Nov 19 2021 15:11:41 GMT+0800 (Malaysia Time)
            return date.toDateString() + ' ' + date.toTimeString()
        }
    },
    getDateTimeDiff (dateFrom="", dateTo="") {
        // input Format: Fri Nov 19 2021 15:11:41 GMT+0800 (Malaysia Time)
    
        const d1 = new Date(dateFrom)
        const d2 = new Date(dateTo)

        // One day in milliseconds
        const oneDay = 1000 * 60 * 60 * 24
        const diffInTime = d2.getTime() - d1.getTime()
        const diffInDay = Math.round(diffInTime / oneDay)
        const diffInHour = Math.round(diffInTime / (1000 * 60 * 60)) // in hour
        const diffInMinute = Math.round(diffInTime / (1000 * 60)) // in minute

        if (diffInDay >= 30) {
            const month = Math.ceil(diffInDay / 30)
            return month + ' ' +( month === 1 ? 'month' : 'months') + ' ago'

        } else if (diffInDay >= 1) {
            return diffInDay + ' ' + ( diffInDay === 1 ? 'day' : 'days') + ' ago'

        } else if (diffInHour >= 1) {
            return diffInHour + ' ' + ( diffInHour === 1 ? 'hour' : 'hours') + ' ago'

        } else if(diffInMinute >= 1) {
            return diffInMinute + ' ' + ( diffInMinute === 1 ? 'minute' : 'minutes') + ' ago'

        } else {
            const diffInSecond = Math.round(diffInTime / 1000)
            return diffInSecond + ' ' + ( diffInSecond === 1 ? 'second' : 'seconds') + ' ago'
        }
    },
    getDateTimeDiffSimple (dateFrom="", dateTo="") {
        // input Format: Fri Nov 19 2021 15:11:41 GMT+0800 (Malaysia Time)
    
        const d1 = new Date(dateFrom)
        const d2 = new Date(dateTo)

        // One day in milliseconds
        const oneDay = 1000 * 60 * 60 * 24
        const diffInTime = d2.getTime() - d1.getTime()
        const diffInDay = Math.round(diffInTime / oneDay)
        const diffInHour = Math.round(diffInTime / (1000 * 60 * 60)) // in hour
        const diffInMinute = Math.round(diffInTime / (1000 * 60)) // in minute

        if (diffInDay >= 30) {
            const month = Math.ceil(diffInDay / 30)
            return month + ' ' +( month === 1 ? 'month' : 'months')

        } else if (diffInDay >= 1) {
            return diffInDay + ' ' + ( diffInDay === 1 ? 'day' : 'days')

        } else if (diffInHour >= 1) {
            return diffInHour + ' ' + ( diffInHour === 1 ? 'hour' : 'hours')

        } else if(diffInMinute >= 1) {
            return diffInMinute + ' ' + ( diffInMinute === 1 ? 'minute' : 'minutes')

        } else {
            const diffInSecond = Math.round(diffInTime / 1000)
            return diffInSecond + ' ' + ( diffInSecond === 1 ? 'second' : 'seconds')
        }
    },
    convSize (size=0) {
        const mb = size / (1024 * 1024)
        const kb = size / 1024

        if (mb > 0) {
            return mb.toFixed(1) + 'MB'
        } else if(kb > 0) {
            return kb.toFixed(1) + 'KB'
        } else {
            return size + 'B'
        }

    },
    maskText(text="", format="") {
        if (format === 'half') {
            const len = text.length
            const temp = text.substring( 1, len-1)
            return text.substring(0, 1) + temp.replaceAll(/[0-9a-zA-Z,.@']/gi, '*') + text.charAt(len - 1)

        } else {
            return text.replaceAll(/[0-9a-zA-Z,.@']/gi, '*')
        }

    },
    convError(error={"message": "", "code": 0, "status": ""}) {
        if (error.status === 'user_not_found') {
            return 'Sorry, you are either not registered or you have forgotted your account. Please register for a new account. (' + error.code + ')'

        } else if (error.status === 'no_privilege') {
            return 'Sorry, you do not have permission to access this page. (' + error.code + ')'

        } else if (error.status === 'invalid_open_id') {
            return 'Username is registered with OpenID, please login via Open ID e.g. Google, Facebook, Yahoo or Microsoft. (' + error.code + ')'

        } else if (error.status === 'user_not_found') {
            return 'User is not exists. (' + error.code + ')'

        } else if (error.status === 'action_not_match') {
            return 'You might have done something unusual. Please report the error (error code) to our support if you continue to face the same problem. (' + error.code + ')'

        } else if (error.status === 'deleting_user') {
            return 'We found that your account is expired. Sorry to inform that we are clearing your account, kindly register a new account again after a minute later'
        
        } else if (error.status === 'no_permission' && error.code === 30016) {
            return 'Sorry, cannot delete this signee as he/she in signing process'

        } else {
            // console.info('code', error.code, typeof error.code)
            // return error.message + '. ' + error.code + ': ' + error.status
            return 'Something unsual. Please report the error (error code) to our support at support@signon.my. (' + error.code + ')'
        }
        
    },
    clearPrevLocalStorage() {
        // ProjDoc
        localStorage.removeItem('leftSignDrawer')
        // localStorage.removeItem('leftFloatDrawer')

        // View
        // localStorage.removeItem('hasClickViewToolbox')
        localStorage.removeItem('leftViewDrawer')
        // localStorage.removeItem('leftViewFloatDrawer')

        // Signatory
        localStorage.removeItem('hasClickSignatoryToolbox')
        localStorage.removeItem('leftDrawer')
        localStorage.removeItem('signeeClass')
        localStorage.removeItem('signeeId')
        localStorage.removeItem('signeeName')
        localStorage.removeItem('signeeEmail')
        localStorage.removeItem('signee')
        localStorage.removeItem('documentId')
        localStorage.removeItem('documentName')
        localStorage.removeItem('documentUrl')
        localStorage.removeItem('doc')

        // View, ProjDoc, Signatory
        localStorage.removeItem('page')
        localStorage.removeItem('totalPage')

    }

}